var currency = require("currency.js");
var dateFns = require("date-fns");

const root = __dirname;

// inputString can start with a dash (negative numbers) but only as it's first character
const inputStringHasBadDashCharcters = (inputString) =>
  new RegExp(/^.+-/).test(inputString);

const inputStringWithDashCharactersRemoved = (inputString) =>
  currency(inputString.replaceAll(`-`, ``), { precision: 0 }).inputString;

module.exports = {
  debug: process.env.NODE_ENV === 'development',
  i18n: {
    defaultLocale: "en",
    locales: ["en"],
  },
  // @TODO: This is like a glass jenga tower, it is going to break spectacularlly at some point.
  localePath: root + "../../../../public/locales",
  keySeparator: '>',
  nsSeparator: '|',
  serializeConfig: false,
  interpolation: {
    format: function(value, format) {
      if (format === "currency") {
        if (value === "-" || value === "-$" || value === "$0-") {
          return "-";
        }

        if (value && typeof value === "string") {
          const regex = /(-[$]?[^0-9]$)/gi;
          const test = new RegExp(regex).test(value);
          if (test) {
            const removedLastChar = value.substring(0, value.length - 1);
            return removedLastChar;
          }

          if (inputStringHasBadDashCharcters(value)) {
            return inputStringWithDashCharactersRemoved(value);
          }
        }

        return currency(value, { precision: 0 }).format();
      }

      if (format === "currencyToNumber") {
        if (value === "-" || value === "-$" || value === "$0-") {
          return 0;
        }

        if (inputStringHasBadDashCharcters(value)) {
          return inputStringWithDashCharactersRemoved(value);
        }

        return currency(value, { precision: 0 }).value;
      }
      if (format === "currencyWithTwoDecimals") {
        return currency(value, { precision: 2 }).format();
      }
      if (format === "percentage") {
        return `${value}%`;
      }
      if (format === "monthYear") {
        if (!value) return value;
        //If the user is typing a slash, we return it instead of removing it like we do for other characters
        if (value.length === 3) {
          const slash = value[2];
          if (slash === "/") {
            return value;
          }
        }
        const monthYear = value.replace(/[^\d]/g, "");
        const monthYearLength = monthYear.length;
        if (monthYearLength < 3) return monthYear;
        const toReturn = `${monthYear.slice(0, 2)}/${monthYear.slice(2, 6)}`;
        return toReturn;
      }
      if (format === "phone") {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
          return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }
      if (format === "SSN") {
        // remove all non-dash and non-numerals
        let val = value.replace(/[^\d-]/g, "");

        // add the first dash if number from the second group appear
        val = val.replace(/^(\d{3})-?(\d{1,2})/, "$1-$2");

        // add the second dash if numbers from the third group appear
        val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, "$1-$2-$3");

        // remove misplaced dashes
        val = val
          .split("")
          .filter((val, idx) => {
            return val !== "-" || idx === 3 || idx === 6;
          })
          .join("");

        // enforce max length
        return val.substring(0, 11);
      }
      if (format === "EIN") {
        // remove all non-dash and non-numerals
        let val = value.replace(/[^\d-]/g, "");

        // add the first dash if number from the second group appear
        val = val.replace(/^(\d{2})-?(\d{1,2})/, "$1-$2");

        // remove misplaced dashes
        val = val
          .split("")
          .filter((val, idx) => {
            return val !== "-" || idx === 2;
          })
          .join("");

        // enforce max length
        return val.substring(0, 10);
      }
      if (format === "humanReadableDate") {
        const date = dateFns.parseJSON(value);
        return dateFns.format(date, "MMM d, yyyy");
      }
      return value;
    },
  },
};
